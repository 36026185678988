/* OE card
-------------------------------------------------- */
.oe-card {
  border-color: $oe-yellow;
  margin: $container-space 0;

  .card-header {
    color: $oe-black;
    border-color: $oe-yellow-dark;
    background-color: $oe-yellow;
  }

  @media screen and (min-width: $screen-s-bs) {
    .network-link-container {
      width: percentage(1/3);
      padding: 3%;
  
      .network-image {
        margin: auto;
      }
    }
  }
}
  